import { useRef } from "react";
import { Each } from "../../../../../../common/components";
import Button from "../../../../../../common/components/atoms/Button/Button";
import { useHighlander } from "../../../../../../common/hooks/useHighlander";
import { usePreserveOrder } from "../../../../../../common/hooks/usePreserveOrder";
import ObjectiveViewCard from "./ObjectiveViewCard";

const ObjectiveViewCardGroup = ({ objectives, fetchStatus, onViewCascades, onViewComments, hasMore, fetchMore }) => {
    const ordered = usePreserveOrder(objectives);
    const [highlander, toggleHighlander] = useHighlander();
    const endOfListRef = useRef(null);

    const handleFetchMore = () => {
        fetchMore();
        endOfListRef.current.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <div className="flex flex-col flex-1 justify-between space-y-8">
            <div className="flex-1 flex flex-col">
                <Each
                    of={ordered}
                    emptyStateProps={{ type: "noIcon", message: "No Objectives To Show", className:"flex-1" }}
                    render={(objective) => {
                        return (
                            <ObjectiveViewCard
                                fadeMe={highlander && highlander !== objective.id}
                                toggled={highlander === objective.id}
                                onToggle={() => toggleHighlander(objective.id)}
                                onViewCascades={onViewCascades}
                                onViewComments={onViewComments}
                                locked={true}
                                objective={objective}
                            />
                        );
                    }}
                />
            </div>
            <div ref={endOfListRef} className="flex justify-center">
                {!hasMore ? null : (
                    <Button onClick={handleFetchMore} disabled={fetchStatus === "loading" || !hasMore}>
                        {fetchStatus === "loading" ? "Loading..." : "Load More"}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default ObjectiveViewCardGroup;
