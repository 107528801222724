import { merge } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import applogger from "../../../../../../common/utils/applogger";
import { LoadingIndicator } from "../../../../../../components";
import { useCustomFields } from "../../../../../../features/CustomFields/hooks/useCustomFields";
import { useEmployeeRelationships } from "../../../../../../features/Employees/hooks/useEmployeeRelationships";
import { useIterationObjectiveListener } from "../../../../../../features/Objectives/hooks/useIterationObjectiveListener";
import ObjectiveReviewEditorContainer from "../../../../../../features/Reviews/containers/modals/containers/ObjectiveReviewEditorContainer";
import TalentReviewContainer from "../../../../../../features/Reviews/containers/modals/containers/TalentReviewContainer";
import { useReviewActions } from "../../../../../../features/Reviews/hooks/useReviewActions";
import { useReviewListeners } from "../../../../../../features/Reviews/hooks/useReviewListeners";
import { useOrgNodeContext } from "../../OrgNodePage";
import { useClaims } from "../../../../../../features/User/hooks/useClaims";
import { confirmFirst } from "../../../../../../common/utils/confirmFirst";

function selectAssessment(isCurrentIteration, savedReview, activeAssessment) {
    // If the cycle isn't live, use the save flags and assessment data
    if (isCurrentIteration) {
        return activeAssessment;
    } else {
        const { flags = {}, ratings = {} } = savedReview || {};
        return { flags, ratings };
    }
}

function useRelationship(reviewerId, employeeId) {
    const { checkRelationship } = useEmployeeRelationships();
    const relationship = useMemo(() => {
        return checkRelationship(reviewerId, employeeId);
    }, [checkRelationship, reviewerId, employeeId]);
    return relationship;
}

const ReviewPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [iterationObjectives, setIterationObjectives] = useState({});
    const userEmployeeId = useSelector((state) => state.user.userEmployeeId);
    const { employeeId, cycleId, iterationId, reviewerId } = params;
    const searchParams = new URLSearchParams(location.search);
    const approvalRequired = searchParams.has("approval");
    const { isSelf, hasChildren, liveObjectives, activeEmployee, activeAssessment, scheduleActions } = useOrgNodeContext();
    const { assessmentFields } = useCustomFields();
    const { isAdmin } = useClaims();
    const relationship = useRelationship(reviewerId, employeeId);
    const reviewData = useReviewListeners(employeeId, cycleId, iterationId, reviewerId);
    const { revieweeRef, reviewerRef, savedReview, cycle, notes, listenersAreReady } = reviewData;
    const { saveDraftReview, submitReview, approveReview } = useReviewActions(
        cycleId,
        iterationId,
        employeeId,
        reviewerRef,
        revieweeRef
    );
    const currentIterationId = useMemo(() => cycle?.currentIterationId, [cycle]);
    const isCurrentIteration = useMemo(() => currentIterationId === iterationId, [currentIterationId, iterationId]);
    useIterationObjectiveListener(employeeId, iterationId, setIterationObjectives);
    const locked = useMemo(() => currentIterationId !== iterationId, [currentIterationId, iterationId]);
    const initialFlagsAndRatings = useMemo(() => {
        return selectAssessment(isCurrentIteration, savedReview, activeAssessment);
    }, [isCurrentIteration, savedReview, activeAssessment]);
    const unsubmittedSaves = savedReview?.unsubmittedSaves || 0;

    useEffect(() => {
        if (!isAdmin) {
            if (userEmployeeId !== reviewerId) {
                // User is not the reviewer (and not an admin); navigate away
                navigate(-1);
            }
            switch (relationship) {
                case "manager":
                case "employee":
                    // User is the employee or the manager; remain on page
                    break;
                default:
                    // User is not the employee or the manager;
                    navigate(-1);
            }
        }
    }, [navigate, isAdmin, userEmployeeId, reviewerId, relationship]);

    // Merge live and iteration objectives
    const objectives = useMemo(() => {
        if (!isCurrentIteration) return iterationObjectives;
        return merge({}, liveObjectives, iterationObjectives);
    }, [liveObjectives, iterationObjectives, isCurrentIteration]);

    const includeNotesPage = useMemo(() => {
        if (cycle?.cycleType === "objectives") return false;
        switch (relationship) {
            case "employee": {
                const hasNotes = cycle?.employeeNotes?.length > 0;
                const hasFlags = cycle?.employeeFlags?.length > 0;
                return hasNotes || hasFlags;
            }
            case "manager": {
                const hasNotes = cycle?.managerNotes?.length > 0;
                const hasFlags = cycle?.managerFlags?.length > 0;
                return hasNotes || hasFlags;
            }
            default:
                return false;
        }
    }, [relationship, cycle]);

    const includeFlagsPage = useMemo(() => {
        switch (relationship) {
            case "employee": {
                return cycle?.employeeFlags?.length > 0;
            }
            case "manager": {
                return cycle?.managerFlags?.length > 0;
            }
            default:
                return false;
        }
    }, [relationship, cycle]);

    const handleSaveDraft = (draft) => {
        if (locked) return;
        saveDraftReview(draft);
    };

    const handleToggle = () => {
        navigate(-1);
    };

    const approve = async () => {
        applogger.info("Approving review...");
        try {
            await approveReview();
            handleToggle();
            toast.success("Review approved");
        } catch (error) {
            applogger.error("Error approving review", error);
        }
    };

    const submitChanges = async (draft) => {
        try {
            applogger.info("Submitting review...");
            setIsSubmitting(true);
            await saveDraftReview(draft);
            await submitReview(relationship, draft, scheduleActions, iterationId, cycle?.cycleType);
            toast.success("Review submitted");
            handleToggle();
        } catch (error) {
            applogger.error("Error submitting review", error);
        }
    };

    const handleSubmit = async (draft, shouldSubmit) => {
        if (shouldSubmit) {
            let counterpartLabel = relationship === "manager" ? "your employee" : "your manager";
            counterpartLabel = `This will make your review visible to ${counterpartLabel}.`;
            const shareable = cycle?.cycleType === "objective";
            confirmFirst({
                title: shareable ? "Share & Submit?" : "Submit?",
                message: shareable ? counterpartLabel : "This will complete the review.",
                onConfirm: () => submitChanges(draft),
            });
        } else {
            confirmFirst({
                title: approvalRequired ? "Approve Review?" : "Confirm Review?",
                message: approvalRequired ? "Review will be approved & completed." : "You've made no changes.",
                onConfirm: () => approve(),
            });
        }
    };

    const values = {
        isSelf,
        hasChildren,
        isSubmitting,
        locked,
        userEmployeeId,
        cycle,
        employeeId: employeeId,
        cycleId: cycleId,
        includeNotesPage,
        includeFlagsPage,
        unsubmittedSaves,
        approvalRequired,
        iterationId: iterationId,
        savedReview: { ...savedReview, employeeId, cycleId, iterationId, reviewerId },
        liveObjectives: objectives, // Need to clean up this confusing naming
        savedNotes: notes || [],
        activeEmployee,
        initialFlagsAndRatings: initialFlagsAndRatings,
        relationship,
        assessmentFields,
    };

    const actions = {
        onSaveDraft: handleSaveDraft,
        onSubmit: handleSubmit,
        onToggle: handleToggle,
    };

    if (!listenersAreReady) return <LoadingIndicator fullscreen />;
    switch (cycle?.cycleType) {
        case "objectives":
            return <ObjectiveReviewEditorContainer values={values} actions={actions} />;
        case "talent":
            return <TalentReviewContainer values={values} actions={actions} />;
        default:
            return null;
    }
};

export default ReviewPage;
