import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { getUniqueId } from "../../helpers/helpers";

export const MyRadioGroup = ({ options, value, onChange, disabled, vertical = false, checkedColor = "primary" }) => {
    const groupName = useMemo(() => {
        const groupName = getUniqueId();
        return groupName;
    }, []);

    const preppedOptions = useMemo(() => {
        return options.map((option) => {
            // create a valid key from the value
            const key = String(option.id || option.value);
            return {
                ...option,
                id: option.id || key,
            };
        });
    }, [options]);

    const containerClassName = vertical ? "flex flex-col" : "flex space-x-8";
    return (
        <div className={containerClassName}>
            {preppedOptions.map((option) => {
                const checked = value === option.value;
                const optionDisabled = option.disabled || disabled;
                return (
                    <div key={`view-switch-${option.id}`} className="form-control">
                        <label className="label cursor-pointer space-x-1 flex">
                            <input
                                type="radio"
                                name={groupName}
                                disabled={optionDisabled}
                                className={classNames("radio bg-white", `radio-${checkedColor}`)}
                                onChange={() => onChange(option.value)}
                                checked={checked}
                            />
                            <span className="label-text">{option.label}</span>
                        </label>
                    </div>
                );
            })}
        </div>
    );
};

export const MyRadioButtons = ({
    options,
    border = true,
    value,
    vertical,
    onChange,
    fixedWidth,
    disabled,
    size = "md",
    checkedColor = "primary",
}) => {
    const [pendingValue, setPendingValue] = useState(value);

    useEffect(() => {
        if (value !== pendingValue) {
            setPendingValue(value);
        }
    }, [value, pendingValue]);

    const isLoading = value !== pendingValue;

    const handleChange = (newValue) => {
        if (pendingValue === newValue) return;
        setPendingValue(newValue);
        onChange(newValue);
    };

    let style = {};
    if (fixedWidth) {
        style = { width: fixedWidth };
    }

    const preppedOptions = useMemo(() => {
        return options.map((option) => {
            // create a valid key from the value
            const key = String(option.id || option.value);
            return {
                ...option,
                id: option.id || key,
            };
        });
    }, [options]);

    return (
        <div
            className={classNames("join border w-fit", {
                "flex flex-col rounded-none": vertical,
                "border-base-300": border,
            })}
        >
            {preppedOptions.map((option) => {
                const checked = value === option.value;
                const buttonColor = option.color || checkedColor;
                const colorClass = checked && `btn-${buttonColor}`;
                const optionDisabled = option.disabled || disabled;
                return (
                    <button
                        style={style}
                        disabled={isLoading || (optionDisabled && !checked)}
                        className={classNames(
                            "join-item btn",
                            "focus:outline-none focus:ring-0 focus:border-transparent",
                            `btn-${size}`,
                            colorClass,
                            { "text-left": vertical }
                        )}
                        key={`view-switch-${option.id}`}
                        aria-label={option.label}
                        onClick={() => handleChange(option.value)}
                    >
                        {option.label}
                        {isLoading && <span className="loading loading-spinner loading-xs"></span>}
                    </button>
                );
            })}
        </div>
    );
};

export default MyRadioButtons;
