import { lazy } from "react";
// Employee Pages
import DevelopmentPlanPage from "./organisation/outlets/OrgNode/outlets/employee/DevelopmentPlanPage";
import ManagerAssessmentPage from "./organisation/outlets/OrgNode/outlets/employee/ManagerAssessmentPage";
import NotesPage from "./organisation/outlets/OrgNode/outlets/employee/NotesPage";
import ObjectiveViewPage from "./organisation/outlets/OrgNode/outlets/employee/ObjectiveViewPage";
import ObjectivesPage from "./organisation/outlets/OrgNode/outlets/employee/ObjectivesPage";
import PreviewPage from "./organisation/outlets/OrgNode/outlets/employee/PreviewPage";
import ReviewPage from "./organisation/outlets/OrgNode/outlets/employee/ReviewPage";
import ReviewQueuePage from "./organisation/outlets/OrgNode/outlets/employee/ReviewQueuePage";
import SelfAssessmentPage from "./organisation/outlets/OrgNode/outlets/employee/SelfAssessmentPage";
// Role Pages
import RoleOverviewPage from "./organisation/outlets/OrgNode/outlets/role/RoleOverviewPage";
import RoleTalentMapPage from "./organisation/outlets/OrgNode/outlets/role/RoleTalentMapPage";

// Cycle Pages
import LiveCyclePages from "./cycles";
import ReviewHistoryPage from "./organisation/outlets/OrgNode/outlets/employee/ReviewHistoryPage";

// Workspace Pages
const WorkspacePage = lazy(() => import("./workspace/WorkspacePage"));

// Org Pages
const OrgPage = lazy(() => import("./organisation/OrgPage"));
const OrgChartPage = lazy(() => import("./organisation/outlets/OrgChartPage"));
const OrgNodePage = lazy(() => import("./organisation/outlets/OrgNode/OrgNodePage"));
const OrgTablePage = lazy(() => import("./organisation/outlets/OrgTablePage"));
const TalentMapPage = lazy(() => import("./organisation/outlets/TalentMapPage"));

// Business Unit Pages
const BusinessUnitsPage = lazy(() => import("./businessUnits/BusinessUnitsPage"));

// Talent Pages
const ActionPage = lazy(() => import("./actions/ActionPage"));
const ActionCompletedPage = lazy(() => import("./actions/ActionCompletedPage"));
const ActionScheduledPage = lazy(() => import("./actions/ActionScheduledPage"));
const ActionCandidatesPage = lazy(() => import("./actions/ActionCandidatesPage"));
const ActionLinkedTraitsPage = lazy(() => import("./actions/ActionLinkedTraitsPage"));
const ActionsPage = lazy(() => import("./actions/ActionsPage"));
const ActionConfigPage = lazy(() => import("./actions/ActionConfigPage"));
const TalentBoardPage = lazy(() => import("./talentboards/TalentBoardPage/TalentBoardPage"));
const BoardConfigOutlet = lazy(() => import("./talentboards/TalentBoardPage/outlets/BoardConfigOutlet"));
const BenchStrengthOutlet = lazy(() => import("./talentboards/TalentBoardPage/outlets/BenchStrengthOutlet"));
const TalentBoardsPage = lazy(() => import("./talentboards/TalentBoardsPage"));
const TraitsPage = lazy(() => import("./traits/TraitsPage"));
const TalentAreasPage = lazy(() => import("./talentareas/TalentAreasPage"));
const ThresholdOutlet = lazy(() => import("./talentboards/TalentBoardPage/outlets/ThresholdOutlet"));

// Settings Pages
const ProfileSettingsPage = lazy(() => import("./settings/ProfileSettingsPage"));
const SettingsIndexPage = lazy(() => import("./settings/SettingsIndexPage"));
const UploadWorkspacePage = lazy(() => import("./settings/dothings/UploadWorkspacePage"));
const WorkspaceMaintenancePage = lazy(() => import("./settings/dothings/WorkspaceMaintenancePage"));
const CustomFieldSettingsPage = lazy(() => import("./settings/workspace/CustomFieldSettingsPage"));
const WorkspaceSettingsPage = lazy(() => import("./settings/workspace/WorkspaceSettingsPage"));

// Sign-in Pages
const SignInPage = lazy(() => import("./sign-in/SignInPage"));
const JoinWorkspace = lazy(() => import("./sign-in/outlets/JoinWorkspace"));
const RequestSignIn = lazy(() => import("./sign-in/outlets/RequestSignIn"));
const CompleteSignIn = lazy(() => import("./sign-in/outlets/CompleteSignIn"));
const SignInSuccess = lazy(() => import("./sign-in/outlets/SignInSuccess"));
const SignInErrorPage = lazy(() => import("../features/ErrorPages/SignInErrorPage"));

const SignInPages = {
    Container: SignInPage,
    Request: RequestSignIn,
    Complete: CompleteSignIn,
    Join: JoinWorkspace,
    Success: SignInSuccess,
    Error: SignInErrorPage,
};

const WorkspacePages = {
    Workspace: WorkspacePage,
};

const BusinessUnitPages = {
    BusinessUnits: BusinessUnitsPage,
};

const OrgPages = {
    Org: OrgPage,
    OrgTable: OrgTablePage,
    OrgChart: OrgChartPage,
    TalentMap: TalentMapPage,
    OrgNode: OrgNodePage,
};

const RolePages = {
    RoleOverview: RoleOverviewPage,
    TalentMap: RoleTalentMapPage,
};

const EmployeePages = {
    ReviewQueue: ReviewQueuePage,
    DevelopmentPlan: DevelopmentPlanPage,
    Notes: NotesPage,
    Objectives: ObjectivesPage,
    ObjectiveView: ObjectiveViewPage,
    SelfAssessment: SelfAssessmentPage,
    ManagerAssessment: ManagerAssessmentPage,
    Review: ReviewPage,
    Preview: PreviewPage,
    History: ReviewHistoryPage,
};

const SettingsPages = {
    Index: SettingsIndexPage,
    Profile: ProfileSettingsPage,
    Upload: UploadWorkspacePage,
    Maintenance: WorkspaceMaintenancePage,
    CustomFields: CustomFieldSettingsPage,
    Workspace: WorkspaceSettingsPage,
};

const TalentPages = {
    TalentAreas: TalentAreasPage,
    Traits: TraitsPage,
    TalentBoards: TalentBoardsPage,
    TalentBoard: TalentBoardPage,
    BenchStrength: BenchStrengthOutlet,
    BoardConfig: BoardConfigOutlet,
    ThresholdConfig: ThresholdOutlet,
    Actions: ActionsPage,
    Action: ActionPage,
    ActionConfig: ActionConfigPage,
    ActionScheduled: ActionScheduledPage,
    ActionCompleted: ActionCompletedPage,
    ActionCandidates: ActionCandidatesPage,
    ActionLinkedTraits: ActionLinkedTraitsPage,
};

export {
    BusinessUnitPages,
    EmployeePages,
    LiveCyclePages,
    OrgPages,
    RolePages,
    SettingsPages,
    SignInPages,
    TalentPages,
    WorkspacePages
};

