import React, { Suspense, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import applogger from "./common/utils/applogger";
import { LoadingIndicator } from "./components";
import { isConfirmLinkValid } from "./firebase/auth";
import { usePathArray } from "./hooks/usePathArray";
import { SignInPages } from "./pages";
import JoinWorkspace from "./pages/sign-in/outlets/JoinWorkspace";
import { useSessionContext } from "./Session";

const SignInApp = () => {
    const navigate = useNavigate();
    const { authStatus, userEmployeeId, isDeveloper, activeWorkspaceId, invites } = useSessionContext();
    const pathArray = usePathArray();
    const signInStage = pathArray[pathArray.length - 1];
    const activePage = window.location.href;
    const validConfirmLink = isConfirmLinkValid(activePage);

    useEffect(() => {
        function successRedirectLink() {
            switch (signInStage) {
                case "request":
                    return "/sign-in/success";
                case "confirm":
                case "join-workspace":
                    if (isDeveloper) return "/organisation/chart";
                    if (userEmployeeId) return `/organisation/view/employees/${userEmployeeId}/things-to-do`;
            }
        }

        if (authStatus === "signed-in") {
            if (isDeveloper || userEmployeeId) {
                const redirectLink = successRedirectLink();
                navigate(redirectLink);
            } else if (invites?.length > 0 && validConfirmLink) {
                applogger.info("user has invites; navigated to join workspace");
                navigate("/sign-in/join-workspace");
            }
        }
    }, [navigate, validConfirmLink, signInStage, activeWorkspaceId, isDeveloper, invites, authStatus, userEmployeeId]);

    function shouldHide() {
        if (signInStage === "success") return false;
        if (!authStatus) return true;
        if (authStatus === "signed-in" && !invites?.length) return true;
        if (authStatus === "not-checked") return true;
    }

    if (shouldHide()) return <LoadingIndicator fullscreen message="Checking Sign-In Status" />;
    return (
        <Suspense fallback={<LoadingIndicator fullscreen />}>
            <Routes>
                <Route path="*" element={<SignInPages.Container />}>
                    <Route index element={<Navigate to="request" />} />
                    <Route path="request" element={<SignInPages.Request />} />
                    <Route path="confirm" element={<SignInPages.Complete />} />
                    <Route path="success" element={<SignInPages.Success />} />
                    <Route path="join-workspace" element={<JoinWorkspace />} />
                </Route>
            </Routes>
        </Suspense>
    );
};

export default SignInApp;
