import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import MyButton from "../MyComponents/Button";

const PaginatedList = ({ children, onLoadMore, hideLoadMore, scrollToTop, loadMoreLabel, className }) => {
    const listRef = useRef(null);

    useEffect(() => {
        if (listRef.current) {
            if (scrollToTop) {
                listRef.current.scrollTop = 0;
            } else {
                listRef.current.scrollTop = listRef.current.scrollHeight;
            }
        }
    }, [scrollToTop]);

    const handleLoadMore = () => {
        onLoadMore();
    };

    return (
        <div className={classNames("flex-1 flex flex-col overflow-hidden", className)}>
            <div ref={listRef} className="flex-1 px-3 scrollable">
                <div className={classNames("flex justify-center py-2", { hidden: hideLoadMore })}>
                    <MyButton size="sm" onClick={handleLoadMore}>
                        {loadMoreLabel}
                    </MyButton>
                </div>
                <div>{children}</div>
            </div>
        </div>
    );
};

PaginatedList.propTypes = {
    children: PropTypes.node.isRequired,
    onLoadMore: PropTypes.func.isRequired,
    className: PropTypes.string,
};

PaginatedList.defaultProps = {
    loadMoreLabel: "view older",
    onLoadMore: () => {},
};

export default PaginatedList;
