import React, { useMemo, useState } from "react";
import { Select } from "../../..";
import MyButton from "../../../../../components/MyComponents/Button";
import MinMaxRange from "../../../../../components/MyComponents/MinMaxRange";
import { getUniqueId } from "../../../../utils/getUniqueId";

const getFilterOptions = (fieldIds, fieldOptions) => {
    const fieldId = fieldIds[0];
    const field = fieldOptions.find((field) => field.id === fieldId);
    return field?.filterOptions || {};
};

const FilterCreator = ({ fieldOptions, onAdd }) => {
    const [filterField, setFilterField] = useState(null);
    const [filter, setFilter] = useState({});
    const disableAdd = Object.keys(filter).length === 0;
    const filterGroupFieldId = "filterGroup";

    const preppedOptions = useMemo(() => {
        return fieldOptions.map((option) => {
            const filterGroup = option?.filterOptions?.[filterGroupFieldId];
            if (!filterGroup) {
                return { ...option };
            } else {
                return { ...option, filterGroup };
            }
        });
    }, [fieldOptions, filterGroupFieldId]);

    const handleChangeField = (fieldIds) => {
        if (!fieldIds || fieldIds.length === 0) {
            setFilterField(null);
            setFilter({});
            return;
        }
        const filterOptions = getFilterOptions(fieldIds, fieldOptions);
        setFilterField({ filterOptions: filterOptions, ids: fieldIds });
        if (filterOptions?.fieldType === "range") {
            setFilter({
                minValue: filterOptions?.min,
                maxValue: filterOptions?.max,
            });
        } else {
            setFilter({});
        }
    };

    const handleChangeSelectValue = (value) => {
        const newValues = Array.isArray(value) ? value : [value];
        setFilter({ matchAny: newValues });
    };

    const handleChangeRangeValue = (value) => {
        setFilter(value);
    };

    const handleAddFilters = () => {
        const { ids, filterOptions } = filterField;
        const baseFilter = {
            fieldType: filterOptions?.fieldType,
            ...filter,
        };
        let filters = [];
        for (let fieldToFilter of ids) {
            const filterId = getUniqueId();
            let fullFilter = {
                filterId,
                fieldId: fieldToFilter,
                ...baseFilter,
            };
            filters.push(fullFilter);
        }
        onAdd(filters);
        setFilterField(null);
        setFilter({});
    };

    const renderValuePicker = () => {
        const filterOptions = filterField?.filterOptions || {};
        const { valueOptions = [] } = filterOptions;

        const preppedOptions = valueOptions.map((option, i) => {
            return {
                id: i,
                label: option,
            };
        });

        switch (filterOptions?.fieldType) {
            case "select":
            case "likertScale":
                return (
                    <Select
                        isMulti
                        options={preppedOptions}
                        selectedIds={filter?.matchAny}
                        onChange={handleChangeSelectValue}
                        placeholder="Matches any of.."
                    />
                );
            case "binary":
                return (
                    <Select
                        selectedIds={filter?.matchAny}
                        options={preppedOptions}
                        onChange={handleChangeSelectValue}
                        placeholder="Is"
                    />
                );
            case "range":
                return (
                    <MinMaxRange
                        onChange={handleChangeRangeValue}
                        step={filterOptions.step}
                        minLimit={filterOptions.min}
                        maxLimit={filterOptions.max}
                        minValue={filter?.minValue || 0}
                        maxValue={filter?.maxValue || 100}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="flex flex-col justify-between">
            <div className="flex flex-col space-y-4">
                <Select
                    isMulti
                    multiGroupFieldId={filterGroupFieldId}
                    options={preppedOptions}
                    selectedIds={filterField?.ids || []}
                    groupByField="subCategory"
                    onChange={handleChangeField}
                    maxMenuHeight={260}
                    placeholder="Field To Filter"
                />
                {renderValuePicker()}
            </div>
            <div className="flex justify-end mt-5">
                <MyButton
                    disabled={disableAdd}
                    color="success"
                    extraClasses="text-base-100"
                    size="sm"
                    onClick={handleAddFilters}
                >
                    ADD FILTER
                </MyButton>
            </div>
        </div>
    );
};

export default FilterCreator;
