import classNames from "classnames";
import Fuse from "fuse.js";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import SelectSearch from "react-select-search";
import "react-select-search/style.css";
import { EMPLOYEE_SEARCH_SETTINGS } from "../../constants";
import { useEmployeeFields } from "../Employees/hooks/useEmployeeFields";
import { usePreppedEmployees } from "../Employees/hooks/usePreppedEmployees";

const GlobalSearchField = ({ className, placeholder, onSelect }) => {
    const allEmployees = useSelector((state) => state.businessUnit.employees);
    const { filteredFields } = useEmployeeFields(EMPLOYEE_SEARCH_SETTINGS.keys);
    const employees = usePreppedEmployees(allEmployees, filteredFields);

    const employeeList = useMemo(() => {
        const newEmployeeList = employees
            .map((employee) => {
                let searchItem = { ...employee, value: employee.id };
                if (searchItem.jobTitle) {
                    searchItem.name = `${searchItem.displayName} - ${searchItem.jobTitle}`;
                }
                return searchItem;
            })
            .filter((employee) => !!employee.name && !!employee.value && employee.roleId);
        return newEmployeeList;
    }, [employees]);

    function fuzzySearch(options = []) {
        const fuse = new Fuse(options, EMPLOYEE_SEARCH_SETTINGS);
        return (value) => {
            if (!value.length) {
                return [];
            }
            const filtered = fuse.search(value);
            const returnList = filtered.map((item) => {
                return { ...item.item };
            });
            return returnList;
        };
    }

    const handleChange = (value) => {
        onSelect(value);
    };

    if (employeeList.length === 0) return null;
    return (
        <SelectSearch
            className={classNames("select-search", className)}
            name="global-employee-search"
            options={employeeList}
            filterOptions={fuzzySearch}
            onChange={handleChange}
            search
            placeholder={placeholder}
        />
    );
};

export default GlobalSearchField;
