import React, { useCallback } from "react";

const MyDrawer = ({ children, side, isOpen, setIsOpen, noClickClose }) => {
    const handleOverlayClick = useCallback(() => {
        if (noClickClose) return;
        setIsOpen((prev) => !prev);
    }, [noClickClose, setIsOpen]);

    return (
        <div className="drawer drawer-end overflow-hidden">
            <input
                id="my-drawer"
                type="checkbox"
                className="drawer-toggle"
                checked={isOpen || false}
                onChange={() => setIsOpen((prev) => !prev)}
            />
            <div className="drawer-content flex flex-col overflow-hidden">{children}</div>
            <div className="drawer-side z-50">
                <label
                    htmlFor="my-drawer"
                    aria-label="close sidebar"
                    className="drawer-overlay"
                    onClick={handleOverlayClick}
                ></label>
                <div className="bg-base-100 h-screen overflow-auto min-w-lg">{side}</div>
            </div>
        </div>
    );
};

export default MyDrawer;
