import { lazy } from "react";

const ListContainer = lazy(() => import("./LiveCyclesContainer"));
const CycleList = lazy(() => import("./outlets/LiveCyclesListOutlet"));
const CyclePage = lazy(() => import("./outlets/CyclePage/ActiveCyclePage"));
const Tracking = lazy(() => import("./outlets/CyclePage/outlets/TrackingOutlet"));
const Results = lazy(() => import("./outlets/CyclePage/outlets/Iterations/outlets/ResultsOutlet"));
const IterationList = lazy(() => import("./outlets/CyclePage/outlets/Iterations/outlets/IterationListOutlet"));
const Iterations = lazy(() => import("./outlets/CyclePage/outlets/Iterations/Iterations"));

const LiveCyclePages = {
    ListContainer,
    CycleList,
    CyclePage,
    Tracking,
    Iterations,
    IterationList,
    Results,
};

export default LiveCyclePages;
