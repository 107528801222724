import { ensureSerializable } from "../../../common/utils/objectUtils";
import { removeUndefined } from "../../../helpers/helpers";
import { createObjectiveSnapshot } from "../../Objectives/utils/objectiveSnapshotUtils";
import { calculateTalentBoardRatings } from "../../TalentBoards/utils/talentBoardUtils";
import { getBoardTraitIds } from "../../Workspace/utils/workspaceHelpers";
import { normalizeRating } from "./normalizeRating";

// Helpers
function prepEmployeeSnapshot({ employeeId, thisEmployee, rawSnapshot, traits, talentBoards, talentAreas }) {
    const linkedTalentBoardIds = thisEmployee?.linkedTalentBoardIds || [];
    const linkedTraitIds = getBoardTraitIds(linkedTalentBoardIds, talentBoards, traits);
    // Clean to only include current applied ratings
    let preppedRatings = {};
    for (const traitId of linkedTraitIds) {
        const rating = rawSnapshot?.ratings?.[traitId];
        if (rating) {
            const normalizedRating = normalizeRating(rating);
            preppedRatings[traitId] = normalizedRating;
        }
    }
    // eslint-disable-next-line
    let { flags, ratings, obj, ...toClean } = rawSnapshot;
    let cleaned = ensureSerializable(toClean);
    let newSnapshot = {
        ...cleaned,
        flags: flags || {},
        ratings: preppedRatings,
    };
    const overallRating = calculateRatingAverage(Object.values(preppedRatings));
    const talentAreaRatings = calculateTalentAreaRatings(newSnapshot, talentAreas, traits, true);
    const talentBoardRatings = calculateTalentBoardRatings(thisEmployee, newSnapshot, talentBoards, traits);
    const objectiveSnapshot = createObjectiveSnapshot(obj, employeeId);

    newSnapshot = {
        ...newSnapshot,
        overallRating: overallRating,
        talentAreas: talentAreaRatings,
        talentBoards: talentBoardRatings,
        objectives: objectiveSnapshot || {},
    };

    return newSnapshot;
}

function makeRatingsArray(ratings) {
    return Object.entries(ratings ?? {})
        .map(([id, rating]) => ({ id, rating }))
        .filter(({ rating }) => !isNaN(rating));
}

function calculateAverageTraitRatings(theseSnapshots, traitId) {

    const ratings = [];
    // eslint-disable-next-line
    removeUndefined(theseSnapshots);
    for (const [_, { ratings: snapshotRatings = {} }] of Object.entries(theseSnapshots)) {
        if (snapshotRatings.hasOwnProperty(traitId)) {
            const rating = snapshotRatings[traitId];
            if (rating) {
                ratings.push(rating);
            }
        }
    }
    return calculateRatingAverage(ratings);
}

function calculateRatingAverage(ratings) {
    const ratingTotal = ratings.reduce((acc, curr) => acc + curr, 0);
    const maxPossibleRating = ratings.length * 100;
    const rating = Math.round((ratingTotal / maxPossibleRating) * 100);
    return isNaN(rating) ? null : rating;
}

function calculateTalentAreaRatings(thisSnapshot, talentAreas, traits, excludeUnrated) {
    let talentAreaRatings = {};
    Object.values(talentAreas).forEach((talentArea) => {
        const { linkedTraitIds = [] } = talentArea;
        const areaRating = calculateOverallRating(thisSnapshot, linkedTraitIds, traits, excludeUnrated);
        if (areaRating) talentAreaRatings[talentArea.id] = areaRating;
    });
    return talentAreaRatings;
}

function calculateOverallRating(thisSnapshot, traitsToAssess, traits, excludeUnrated) {
    if (!thisSnapshot || traitsToAssess.length === 0) return 0;
    let allRatings = [];
    for (const traitId of traitsToAssess) {
        const traitIsLive = !!traits[traitId];
        if (traitIsLive) {
            const snapshotRatings = thisSnapshot.ratings || {};
            const traitRating = snapshotRatings[traitId] || 0;
            if (traitRating > 0 || !excludeUnrated) {
                allRatings.push(traitRating);
            }
        }
    }
    return calculateRatingAverage(allRatings);
}

function getRating(snapshot, fieldId, fieldValue) {
    const subKey = fieldId === "talentBoards" && "overall";

    if (!snapshot) return null;

    if (fieldId === "overallRating") {
        return snapshot?.overallRating;
    }

    let ratingField = snapshot?.[fieldId];
    if (fieldValue) {
        ratingField = ratingField?.[fieldValue];
    }
    if (subKey) {
        ratingField = ratingField?.[subKey];
    }
    return ratingField;
}

function calculateRatingCounts(theseSnapshots, ratingView, id) {
    let ratingCounts = {};
    Object.values(theseSnapshots).reduce((counts, snapshot) => {
        const rating = getRating(snapshot, ratingView, id);
        if (typeof rating !== "undefined" && !isNaN(rating)) {
            counts[rating] = (counts[rating] || 0) + 1;
        }
        return counts;
    }, ratingCounts);
    delete ratingCounts.NaN;
    delete ratingCounts.undefined;
    return ratingCounts;
}

export function calculateBranchObjectiveOverview(filteredSnapshots) {
    function helper(snapshots, key) {
        const { sum, count } = snapshots.reduce(
            (acc, snap) => {
                if (typeof snap?.[key] === "number") {
                    acc.sum += snap[key];
                    acc.count += 1;
                }
                return acc;
            },
            { sum: 0, count: 0 }
        );
        return Math.round(count > 0 ? sum / count : 0);
    }

    // Flatmap all objectives from snapshots
    const allObjectiveSnapshots = filteredSnapshots.filter((snap) => snap.objectives).map((snap) => snap.objectives);

    const liveCount = allObjectiveSnapshots.reduce((acc, snap) => acc + (snap.liveCount || 0), 0);
    const awaitingCount = allObjectiveSnapshots.reduce((acc, snap) => acc + (snap?.awaitingCount || 0), 0);
    const projected = helper(allObjectiveSnapshots, "projected");
    const performance = helper(allObjectiveSnapshots, "performance");

    return {
        liveCount,
        awaitingCount,
        performance,
        projected,
    };
}

/// Return values from state without subscribing to the store
function showRatingColor(assessmentColors, rating) {
    if (typeof rating === "string" && rating.charAt(0) === "#") return rating;
    if (rating == null || isNaN(rating) || rating === undefined || rating === 0) return "#d3d3d3";
    return assessmentColors[Math.max(0, rating - 1)];
}

function listAxisValue(snap, axis) {
    const { fieldId, fieldValue } = axis;
    return getRating(snap, fieldId, fieldValue);
}

function calculateSnapshotValues(theseSnapshots, traits, talentAreas) {
    let ratings = {};
    Object.keys(traits).forEach((traitId) => {
        const traitRating = calculateAverageTraitRatings(theseSnapshots, traitId);
        if (traitRating) {
            ratings[traitId] = traitRating;
        }
    });
    let snapshot = {
        ratings: ratings,
    };
    snapshot.talentAreas = calculateTalentAreaRatings(snapshot, talentAreas, traits, true);
    return snapshot;
}

export {
    calculateAverageTraitRatings,
    calculateOverallRating,
    calculateRatingAverage,
    calculateRatingCounts,
    calculateSnapshotValues,
    calculateTalentAreaRatings,
    getRating,
    listAxisValue,
    makeRatingsArray,
    prepEmployeeSnapshot,
    showRatingColor
};

