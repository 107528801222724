import classNames from "classnames";
import React from "react";
import MySwitch from "../../components/MyComponents/Switch";
import MyTextInput from "../../components/MyComponents/TextInput";
import IdPicker from "../../components/Picker/IdPicker";
import EmployeePicker from "../Employees/components/EmployeePicker";
import DateSelector from "../../common/components/organisms/DateSelector/DateSelector";

const CustomFieldDisplay = ({
    field,
    value,
    isMulti,
    className,
    excludeFromOptions,
    disabled,
    readOnly,
    hideLabel,
    onChange,
}) => {
    const { id, label, options, fieldType } = field;

    const handleChange = (update) => {
        if (readOnly) return;
        onChange(field.id, update);
    };

    const renderField = () => {
        switch (fieldType) {
            case "binary":
                return (
                    <MySwitch
                        disabled={disabled}
                        onLabel={options[1]}
                        offLabel={options[0]}
                        checked={value === 1 || value === true || value === "true"}
                        onChange={(newVal) => handleChange(newVal ? 1 : 0)}
                    />
                );
            case "select":
            case "likertScale":
                const preppedOptions = options.map((option, i) => ({ label: option, value: i, id: i }));
                return (
                    <IdPicker
                        isMulti={isMulti}
                        label={!hideLabel && label}
                        options={preppedOptions}
                        selectedIds={value}
                        onChange={handleChange}
                        disabled={disabled}
                        unsorted
                    />
                );
            case "date":
                return (
                    <div className="border rounded py-2 flex">
                        <DateSelector value={value} onChange={handleChange} disabled={disabled} readOnly={readOnly} />
                    </div>
                );
            case "employeePicker":
                return (
                    <EmployeePicker
                        label={!hideLabel && label}
                        isMulti
                        includeAll
                        includeOrphans
                        excludeIds={excludeFromOptions}
                        maxMenuHeight={140}
                        selectedIds={value || []}
                        onChange={handleChange}
                        disabled={disabled}
                    />
                );
            default:
                return (
                    <MyTextInput
                        type={fieldType === "numeric" ? "number" : "text"}
                        name={id}
                        disabled={disabled}
                        onChange={(e) => handleChange(e.target.value)}
                        value={value}
                        autoComplete="off"
                    />
                );
        }
    };

    return <div className={classNames(className, "flex-1 flex")}>{renderField()}</div>;
};

export default CustomFieldDisplay;
