import { getDocs } from "firebase/firestore";
import { useCallback, useEffect, useRef, useState } from "react";
import { readTracker } from "../common/utils/activityTracker";
import applogger from "../common/utils/applogger";
import buildQuery from "../firebase/utils/buildQuery";

export function usePaginatedFetched(
    collectionRef,
    whereConditions = [],
    docsPerPage = 10,
    orderByCondition = [["completedOn", "desc"]]
) {
    const [items, setItems] = useState([]);
    const attemptStatus = useRef("idle");
    const lastDoc = useRef(null);
    const isInitialisedRef = useRef(false);
    const hasMoreRef = useRef(true);
    const [hasMore, setHasMore] = useState(true);

    const fetchMore = useCallback(async () => {
        if (!collectionRef) return;
        if (attemptStatus.current === "fetching" || !hasMoreRef.current) return;
        attemptStatus.current = "fetching";
        try {
            let fetchQuery = buildQuery(collectionRef, whereConditions, orderByCondition, lastDoc.current, docsPerPage);
            readTracker(collectionRef);
            const querySnapshot = await getDocs(fetchQuery);
            const newDocs = querySnapshot.docs.map((doc) => {
                return { id: doc.id, ...doc.data() };
            });
            if (newDocs.length > 0) {
                setItems((prevItems) => [...prevItems, ...newDocs]);
                lastDoc.current = querySnapshot.docs[querySnapshot.docs.length - 1];
                if (newDocs.length < docsPerPage) {
                    hasMoreRef.current = false;
                    setHasMore(false);
                }
            } else {
                hasMoreRef.current = false;
                setHasMore(false);
            }
        } catch (error) {
            applogger.error("Error fetching documents: ", error);
        } finally {
            attemptStatus.current = "idle";
        }
    }, [collectionRef, whereConditions, orderByCondition, docsPerPage]);

    // Fetch the first set of documents
    useEffect(() => {
        if (!isInitialisedRef.current) {
            isInitialisedRef.current = true;
            fetchMore();
        }
    }, [fetchMore]);

    return [items, fetchMore, hasMore, attemptStatus.current];
}
