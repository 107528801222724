import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const MyRange = ({ size, step, value, color, thumbColor, disabled, min, max, hideVal, onChange }) => {
    const sizeClass = size && `range-${size}`;
    const colorClass = color && `range-${color}`;
    const labelClass = color && `text-${color}-content`;
    const rangeRef = useRef(null);
    const [_, setThumbPosition] = useState(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        updateThumbPosition();
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (e) => {
        const newVal = parseInt(e.target.value, 10);
        onChange(newVal);
        updateThumbPosition();
    };

    const updateThumbPosition = () => {
        const slider = rangeRef.current;
        if (slider) {
            const percentage = (value - slider.min) / (slider.max - slider.min);
            const thumbWidth = 20; // Adjust based on your thumb width
            const trackWidth = slider.offsetWidth - thumbWidth;
            const position = percentage * trackWidth;
            setThumbPosition(position);
        }
    };

    return (
        <>
            {thumbColor && (
                <style>
                    {`
                    input[type='range']::-webkit-slider-thumb {
                        background: ${thumbColor};
                    }

                    input[type='range']::-moz-range-thumb {
                        background: ${thumbColor};
                    }

                    input[type='range']::-ms-thumb {
                        background: ${thumbColor};
                    }
                `}
                </style>
            )}
            <div className="relative w-full">
                <input
                    ref={rangeRef}
                    type="range"
                    min={min}
                    max={max}
                    value={value}
                    className={classNames("range flex", sizeClass, colorClass, { "opacity-40": disabled })}
                    onChange={handleChange}
                    disabled={disabled}
                    step={step}
                />
            </div>
        </>
    );
};

MyRange.propTypes = {
    size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
    step: PropTypes.oneOf([...Array(101).keys()].map((n) => n)),
    value: PropTypes.oneOf([...Array(101).keys()].map((n) => n)),
    min: PropTypes.number,
    max: PropTypes.number,
    thumbColor: PropTypes.string,
    color: PropTypes.oneOf(["primary", "secondary", "success", "error", "warning", "info", "neutral", "accent"]),
    style: PropTypes.object,
};

MyRange.defaultProps = {
    min: 0,
    step: 25,
    max: 100,
    size: "md",
    color: "primary",
};

export default MyRange;
