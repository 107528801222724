import React from "react";
import { useSelector } from "react-redux";
import { useSubordinates } from "../../../../../../features/Employees/hooks/useSubordinates";
import RoleOverviewTemplate from "../../../../../../features/Roles/components/templates/RoleOverviewTemplate";
import { useRoleRelationships } from "../../../../../../features/Roles/hooks/useRoleRelationships";
import { useSnapshots } from "../../../../../../features/Snapshots/hooks/useSnapshots";
import { useOrgNodeContext } from "../../OrgNodePage";

const INCLUDE_TOP_ROLE = false;

const RoleOverviewPage = () => {
    const { roleId, navigateToOrgNode } = useOrgNodeContext();
    const { childRoleIds } = useRoleRelationships(roleId);
    const subordinates = useSubordinates(roleId, INCLUDE_TOP_ROLE);
    const traits = useSelector((state) => state.workspace.traits);
    const talentAreas = useSelector((state) => state.workspace.talentAreas);
    const talentBoards = useSelector((state) => state.workspace.talentBoards);
    const { getFilteredSnapshots } = useSnapshots();

    return (
        <div className="page page-padding">
            <RoleOverviewTemplate
                roleId={roleId}
                subordinates={subordinates}
                childRoleIds={childRoleIds}
                traits={traits}
                talentAreas={talentAreas}
                talentBoards={talentBoards}
                getFilteredSnapshots={getFilteredSnapshots}
                onNavigateToOrgNode={navigateToOrgNode}
            />
        </div>
    );
};

export default RoleOverviewPage;
