import { increment } from "firebase/firestore";
import { groupBy, uniq } from "lodash";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { ROOT_ROLE_ID } from "shared";
import { removeUndefined } from "../../../common/utils/objectUtils";
import { EMPLOYEE_PAGE_PATH } from "../../../constants";
import { batchSet, getDocRef, getSubCollectionRef } from "../../../firebase/firebaseActions";
import { useWorkspaceRef } from "../../../firebase/hooks/useWorkspaceRef";
import { getUniqueId } from "../../../helpers/helpers";
import { useBusinessUnits } from "../../BusinessUnits/hooks/useBusinessUnits";
import { useBusinessUnitRefs } from "../../BusinessUnits/refs/useBusinessUnitRefs";
import { getAllRoleParentRoleIds, getChildEmployeeIds, getChildRoleIds, getRole } from "../../Roles/hooks/useRoles";
import { disableEmployee } from "../utils/employeeDb";

function getDefaultEmployeePageLink(employeeId) {
    return `${EMPLOYEE_PAGE_PATH}/${employeeId}/talent-assessment`;
}

function buildEmployeeSearchObject(employee, role) {
    let searchObject = {
        name: employee?.displayName,
        value: employee?.id,
        jobTitle: role?.jobTitle || "No Job Title",
        ...employee,
    };
    // Remove any undefined values from searchObject
    Object.keys(searchObject).forEach((key) => searchObject[key] === undefined && delete searchObject[key]);
    return searchObject;
}

function getDirectReportIdsHelper(empId, employees, roles, parentMap) {
    const thisEmployee = employees[empId];
    const childRoleIds = getChildRoleIds(thisEmployee.roleId, parentMap);
    return childRoleIds
        .map((roleId) => {
            return getRole(roleId, roles)?.incumbentId;
        })
        .filter(Boolean);
}

function getManagerHelper(empId, employees, roles) {
    const employee = employees[empId];
    const employeeRole = getRole(employee?.roleId, roles);
    const managerRole = getRole(employeeRole?.parentRoleId, roles);
    return employees[managerRole?.incumbentId];
}

function getJobTitleHelper(empId, employees, roles) {
    const employee = employees[empId];
    const employeeRole = getRole(employee?.roleId, roles);
    const jobTitle = employeeRole?.jobTitle;
    return jobTitle;
}

function getAllEmployeeIdsAboveHelper(empId, employees, roles, parentMap) {
    const employee = employees[empId];
    const allParentRoleIds = getAllRoleParentRoleIds(employee?.roleId, parentMap);
    const allEmployeeIds = allParentRoleIds.map((roleId) => {
        const role = getRole(roleId, roles);
        return role?.incumbentId;
    });
    return allEmployeeIds.filter(Boolean);
}

function checkIfTopOfTreeHelper(empId, roles, parentMap) {
    const allTreeHeads = getChildEmployeeIds(ROOT_ROLE_ID, roles, parentMap);
    return allTreeHeads.includes(empId);
}

function checkRelationshipHelper(empId, comparisonId, employees, roles, parentMap) {
    const manager = getManagerHelper(comparisonId, employees, roles);
    const directReports = getDirectReportIdsHelper(empId, employees, roles, parentMap);
    if (empId === comparisonId) return "self";
    if (empId === manager?.id) return "manager";
    if (directReports.includes(comparisonId)) return "directReport";
    return "peer";
}

function checkIfManagerHelper(empId, employees, parentMap) {
    const employee = employees[empId];
    const childRoleIds = getChildRoleIds(employee?.roleId, parentMap);
    return childRoleIds.length > 0;
}

export function useEmployees() {
    const workspaceId = useSelector((state) => state.workspace.workspaceId);
    const businessUnitId = useSelector((state) => state.businessUnit.businessUnitId);
    const employees = useSelector((state) => state.businessUnit.employees);
    const parentMap = useSelector((state) => state.businessUnit.parentMap);
    const roles = useSelector((state) => state.businessUnit.roles);
    const workspaceRef = useWorkspaceRef();
    const { businessUnitRef } = useBusinessUnitRefs();
    const employeesRef = useMemo(() => getSubCollectionRef(workspaceRef, "employees"), [workspaceRef]);

    const { getUnitDocId } = useBusinessUnits();

    const getEmployee = useCallback(
        (empId) => {
            return employees[empId];
        },
        [employees]
    );

    // Ensures a new employee id is unique (ids are short so collision isn't impossible)
    function getNewEmployeeId() {
        const newId = getUniqueId();
        const existingEmployee = employees[newId];
        if (existingEmployee) {
            return getNewEmployeeId();
        } else {
            return newId;
        }
    }

    function getDirectReportIds(empId) {
        return getDirectReportIdsHelper(empId, employees, roles, parentMap);
    }

    function getManager(empId) {
        return getManagerHelper(empId, employees, roles);
    }

    function getJobTitle(empId) {
        return getJobTitleHelper(empId, employees, roles);
    }

    function getAllEmployeeIdsAbove(empId) {
        return getAllEmployeeIdsAboveHelper(empId, employees, roles, parentMap);
    }

    function checkIfTopOfTree(empId) {
        return checkIfTopOfTreeHelper(empId, roles, parentMap);
    }

    function checkRelationship(employeeId, comparisonId) {
        return checkRelationshipHelper(employeeId, comparisonId, employees, roles, parentMap);
    }

    function deleteEmployee(employeeId) {
        const employee = getEmployee(employeeId);
        if (employee) {
            let batch = disableEmployee(workspaceId, employeeId);
            return batch;
        }
    }

    function saveEmployee(employeeId, employeeUpdate, batch) {
        const employeeRef = getDocRef(employeesRef, employeeId);
        const employeeUnitDocId = employeeUpdate.unitDocId || getUnitDocId("employees", 200);
        const update = {
            ...employeeUpdate,
            unitDocId: employeeUnitDocId,
            businessUnitId: businessUnitId,
        };
        removeUndefined(update);
        batch = batchSet(employeeRef, update, batch);
        return batch;
    }

    const checkEmployeeUserStatus = useCallback(
        (empId) => {
            const employee = employees[empId];
            const { isUser, inviteAccepted } = employee || {};
            if (isUser && inviteAccepted) return "active";
            if (isUser && !inviteAccepted) return "pending";
            return "inactive";
        },
        [employees]
    );

    const createBulkUserInviteQueue = useCallback(
        (employeeIds) => {
            let transactionQueue = [];
            const uniqueEmployeeIds = uniq(employeeIds).filter(Boolean);
            let employeeInfoUpdates = [];

            // Create employee update transactions
            // IMPORTANT: importCount is incremented to prevent cloud functions from re-inviting users
            uniqueEmployeeIds.forEach((empId) => {
                const userStatus = checkEmployeeUserStatus(empId);
                if (userStatus === "inactive") {
                    const employeeRef = getDocRef(employeesRef, empId);
                    transactionQueue.push({
                        ref: employeeRef,
                        data: { isUser: true, inviteAccepted: false, userRole: "manager", importCount: increment(1) },
                    });

                    // Queue a unit doc update
                    const employee = employees[empId];
                    const { unitDocId } = employee;
                    if (unitDocId) {
                        const employeeInfoUpdate = { unitDocId, empId };
                        employeeInfoUpdates.push(employeeInfoUpdate);
                    }
                }
            });

            // Add unit doc transactions
            const unitDocUpdates = groupBy(employeeInfoUpdates, "unitDocId");
            const employeeInfoRef = getSubCollectionRef(businessUnitRef, "employeeInfo");
            Object.entries(unitDocUpdates).forEach(([unitDocId, updates]) => {
                const unitDocRef = getDocRef(employeeInfoRef, unitDocId);
                let unitDocUpdate = {};
                updates.forEach((update) => {
                    unitDocUpdate[update.empId] = { userRole: "manager", inviteAccepted: false };
                });
                transactionQueue.push({
                    ref: unitDocRef,
                    data: unitDocUpdate,
                });
            });
            return transactionQueue;
        },
        [employees, businessUnitRef, employeesRef, checkEmployeeUserStatus]
    );

    return {
        getEmployee,
        getDirectReportIds,
        getManager,
        getJobTitle,
        createBulkUserInviteQueue,
        checkIfTopOfTree,
        getAllEmployeeIdsAbove,
        checkRelationship,
        saveEmployee,
        deleteEmployee,
        checkEmployeeUserStatus,
        getNewEmployeeId,
    };
}

export {
    buildEmployeeSearchObject,
    checkIfManagerHelper as checkIfManager,
    checkIfTopOfTreeHelper as checkIfTopOfTree,
    checkRelationshipHelper as checkRelationship,
    getAllEmployeeIdsAboveHelper as getAllEmployeeIdsAbove,
    getDefaultEmployeePageLink,
    getDirectReportIdsHelper as getDirectReportIds,
    getJobTitleHelper as getJobTitle,
    getManagerHelper as getManager
};

