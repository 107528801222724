import React from "react";
import useEditValues from "../../../../../common/hooks/useEditValues";
import NoteContent from "../../../../Notes/components/atoms/NoteContent";

const ObjectiveNote = ({
    note,
    objectiveId,
    readOnly,
    onUpdate,
    className,
    bgClass,
    alwaysShowToolbar,
    placeholder = "No Comment Added Yet.",
}) => {
    const [editedNote, setNoteValue] = useEditValues(note);

    const handleChange = (newContent, originalNote) => {
        setNoteValue("content", newContent);
        setNoteValue("objectiveId", objectiveId);
        const updatedNote = { ...originalNote, content: newContent };
        onUpdate(updatedNote);
    };

    return (
        <NoteContent
            note={editedNote}
            readOnly={readOnly}
            onChange={handleChange}
            className={className}
            alwaysShowToolbar={alwaysShowToolbar}
            bgClass={bgClass}
            placeholder={placeholder}
        />
    );
};

export default ObjectiveNote;
