import React, { useMemo } from "react";
import MyRadioButtons from "../../../components/MyComponents/RadioButtons";
import { useOrgContext } from "../../../pages/organisation/OrgPage";
import OrgTopRolePicker from "./atoms/OrgTopRolePicker";

const viewItems = [
    { label: "Table", value: "table" },
    { label: "Org Chart", value: "chart" },
    { label: "Talent Map", value: "map" },
];

const OrgToolbar = ({ children }) => {
    const {
        isAdmin,
        showToolbar,
        activeTab,
        switchTab,
        hasDirectReports,
    } = useOrgContext();

    const viewOptions = useMemo(() => {
        const options = [...viewItems];
        if (!hasDirectReports && !isAdmin) {
            options.pop();
        }
        return options;
    }, [hasDirectReports, isAdmin]);

    if (!showToolbar) return null;

    const handleSwitchTab = (value) => {
        if (value === activeTab) return;
        switchTab(value);
    };

    return (
        <div className="navbar shadow border-b bg-base-150 flex flex-start space-x-2 w-full px-8">
            <div className="space-x-2 w-fit">
                <MyRadioButtons size="sm" options={viewOptions} value={activeTab} onChange={handleSwitchTab} />
                <OrgTopRolePicker />
            </div>
            <div className="flex-1">{children}</div>
        </div>
    );
};

export default OrgToolbar;
