import React, { useEffect, useMemo, useState } from "react";
import { PageSection } from "../../../../common/components";
import MyEmptyState from "../../../../components/MyComponents/EmptyState";
import EmployeeCard from "../../../Employees/components/EmployeeCard";
import { calculateSnapshotValues, makeRatingsArray } from "../../../Snapshots/utils/snapshotHelpers";
import TalentAreaLegend from "../../../TalentAreas/TalentAreaLegend";
import TalentStrength from "../../../Traits/components/organisms/TalentStrength";

const RoleOverviewTemplate = ({
    roleId,
    children,
    subordinates,
    childRoleIds,
    traits,
    talentAreas,
    talentBoards,
    getFilteredSnapshots,
    onNavigateToOrgNode,
}) => {
    const [talentAreaRatings, setTalentAreaRatings] = useState([]);
    const [ratings, setRatings] = useState([]);

    const filteredSnapshots = useMemo(() => {
        return getFilteredSnapshots(roleId);
    }, [roleId, getFilteredSnapshots]);

    const directReports = useMemo(() => {
        return subordinates.filter((subordinate) => childRoleIds.includes(subordinate.roleId));
    }, [subordinates, childRoleIds]);

    useEffect(() => {
        const departmentSnapshot = calculateSnapshotValues(filteredSnapshots, traits, talentAreas, talentBoards);
        setTalentAreaRatings(makeRatingsArray(departmentSnapshot.talentAreas));
        setRatings(makeRatingsArray(departmentSnapshot.ratings));
    }, [filteredSnapshots, traits, talentAreas, talentBoards]);

    const handleViewEmployee = (roleId, employeeId) => {
        onNavigateToOrgNode(roleId, employeeId);
    };

    return (
        <div className="flex-1 flex flex-col space-y-2">
            {children}
            <div className="flex-1 flex flex-col space-y-8">
                <PageSection title="Team Strengths">
                    <TalentStrength
                        horizontal
                        wordCloudId="departmentTabTeam"
                        className="bg-base-100 rounded-lg overflow-hidden"
                        talentAreaRatings={talentAreaRatings}
                        ratings={ratings}
                    />
                </PageSection>
                <PageSection title="Direct Reports">
                    <div className="p-4 bg-base-100 border rounded flex-1">
                        <div className="flex justify-end">
                            <TalentAreaLegend />
                        </div>
                        <div className="grid grid-cols-12">
                            {directReports.map((employee) => {
                                return (
                                    <div key={employee.id} className="grid-item p-4">
                                        <EmployeeCard employee={employee} onView={handleViewEmployee} />
                                    </div>
                                );
                            })}
                            {directReports.length === 0 && (
                                <div className="col-span-12">
                                    <MyEmptyState message="No direct reports" className="border-none" />
                                </div>
                            )}
                        </div>
                    </div>
                </PageSection>
            </div>
        </div>
    );
};

export default RoleOverviewTemplate;
