import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { LoadingIndicator } from "../../../../components";
import { TeamIcon, ToDoIcon, UserIcon } from "../../../../components/Icons/MyIcons";
import MySideMenu, { prepSideMenuItem } from "../../../../components/MyComponents/SideMenu";
import useCycleListener from "../../../../features/Cycles/hooks/useCycleListener";
import { useEmployeeListener } from "../../../../features/Employees/hooks/useEmployeeListener";
import { useLiveObjectivesListener } from "../../../../features/Objectives/hooks/useLiveObjectiveListener";
import { useObjectiveActions } from "../../../../features/Objectives/hooks/useObjectiveActions";
import OrgNodeDetailsCard from "../../../../features/Organisation/components/OrgNodeDetailsCard";
import { useSelectOrgNodes } from "../../../../features/Organisation/hooks/useSelectOrgNodes";
import ChangeUserRole from "../../../../features/Roles/components/organisms/RoleEditors/ChangeUserRole";
import { useRoleRelationships } from "../../../../features/Roles/hooks/useRoleRelationships";
import { useEmployeeScheduler } from "../../../../features/ScheduledActions/hooks/useEmployeeScheduler";
import { useSnapshots } from "../../../../features/Snapshots/hooks/useSnapshots";
import { useIsThisMe } from "../../../../features/User/hooks/useIsThisMe";
import { usePathArray } from "../../../../hooks/usePathArray";
import { useToggle } from "../../../../hooks/useToggle";
import { deselectOrgNodes } from "../../../../redux/appSlice";
import { useOrgContext } from "../../OrgPage";

const toDoPage = {
    id: "things-to-do",
    title: "To Do",
    icon: ToDoIcon,
    requiresSelf: true,
};

const employeePages = {
    id: "employee",
    title: "displayName",
    icon: UserIcon,
    pages: [
        {
            id: "talent-assessment",
            title: "Talent Assessment",
            requiresNotSelf: true,
        },
        {
            id: "self-assessment",
            title: "Self Assessment",
            requires: "selfAssessment",
        },
        { id: "objectives", title: "Objectives", requires: "objectives" },
        { id: "development-plan", title: "Development Plan", requiresNotSelf: true },
        { id: "history", title: "Review History" },
        //{ id: "notes", title: "Notes" },
    ],
};

const rolePages = {
    id: "role",
    title: "Role",
    icon: TeamIcon,
    requiresChildren: true,
    pages: [
        { id: "role-overview", title: "Role Overview" },
        { id: "talent-map", title: "Talent Map" },
    ],
};

const OrgNodeContext = createContext();

export function useOrgNodeContext() {
    return useContext(OrgNodeContext);
}

const OrgNodePage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const pathArray = usePathArray();
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const [liveObjectives, setLiveObjectives] = useState(null);
    const [cycle, setCycle] = useState(null);
    const [changeRoleIsOpen, toggleChangeRole] = useToggle();
    const subpage = pathArray[pathArray.length - 1];
    const { roleId, employeeId, cycleId } = params;
    const isSelf = useIsThisMe(selectedEmployeeId);
    const isAdmin = useSelector((state) => state.user.isAdmin);
    const orgReady = useSelector((state) => state.businessUnit.orgReady);
    const talentDataLinked = useSelector((state) => state.workspace.talentDataLinked);
    const { navigateToOrgNode, selectEmployee, selectRole } = useOrgContext();
    const { saveObjective } = useObjectiveActions(selectedEmployeeId);
    const { getEmployeePath, getRolePath } = useSelectOrgNodes();
    const { childRoleIds } = useRoleRelationships(selectedRoleId);
    const activeEmployee = useEmployeeListener(selectedEmployeeId);
    const objectiveListener = useLiveObjectivesListener(selectedEmployeeId, setLiveObjectives);
    const cycleListener = useCycleListener(cycleId, setCycle, !!cycleId);
    const { scheduleActions, completeActions, cancelActions } = useEmployeeScheduler(selectedEmployeeId);
    const { getSnapshot } = useSnapshots();
    const snapshot = useMemo(() => getSnapshot(selectedEmployeeId), [getSnapshot, selectedEmployeeId]);
    
    const employeeLinkPrefix = useMemo(
        () => getEmployeePath(selectedEmployeeId),
        [getEmployeePath, selectedEmployeeId]
    );
    const roleLinkPrefix = useMemo(() => getRolePath(selectedRoleId), [getRolePath, selectedRoleId]);
    const hasChildren = childRoleIds.length > 0;

    const pageReady = useMemo(() => {
        const employeeSelected = !employeeId || selectedEmployeeId === employeeId;
        const roleSelected = !roleId || selectedRoleId === roleId;
        const shouldBeTrue = [employeeSelected, roleSelected, orgReady, talentDataLinked];
        const allAreTrue = shouldBeTrue.every((bool) => bool);
        const shouldBeSuccess = [objectiveListener, cycleListener];
        const allAreSuccess = shouldBeSuccess.every((status) => status === "success");
        return allAreTrue && allAreSuccess;
    }, [
        employeeId,
        roleId,
        selectedEmployeeId,
        selectedRoleId,
        orgReady,
        talentDataLinked,
        objectiveListener,
        cycleListener,
    ]);

    // Navigate away if the employee doc doesn't exist
    useEffect(() => {
        if (activeEmployee === "failed") {
            navigate(-1);
        }
    }, [activeEmployee, navigate]);

    // Deselect the org nodes when unmounting
    useEffect(() => {
        return () => {
            dispatch(deselectOrgNodes());
        };
    }, [dispatch]);

    // Ensure the correct entities are selected
    useEffect(() => {
        if (roleId) {
            selectRole(roleId);
        }
        if (employeeId) {
            selectEmployee(employeeId);
        }
    }, [selectRole, selectEmployee, roleId, employeeId]);

    // Prep the pages for the sidemenu
    const pages = useMemo(() => {
        const fieldOverrides = { employee: activeEmployee?.displayName };
        const preppedTodoPage = prepSideMenuItem(toDoPage, employeeLinkPrefix);
        const preppedEmployeePages = prepSideMenuItem(employeePages, employeeLinkPrefix, fieldOverrides);
        const preppedRolePages = prepSideMenuItem(rolePages, roleLinkPrefix, fieldOverrides);
        let newPages = [];
        if (isSelf) {
            newPages.push(preppedTodoPage);
        }
        if (selectedEmployeeId) {
            newPages.push(preppedEmployeePages);
        }
        if (selectedRoleId) {
            newPages.push(preppedRolePages);
        }
        return newPages;
    }, [selectedEmployeeId, isSelf, selectedRoleId, activeEmployee, employeeLinkPrefix, roleLinkPrefix]);

    const handleUnauthorised = () => {
        navigate("/organisation");
    };

    const handleScheduleActions = (actionIds = [], batch) => {
        actionIds = Array.isArray(actionIds) ? actionIds : [actionIds];
        const alreadyScheduled = activeEmployee?.scheduledActionIds || [];
        const actionIdsToSchedule = actionIds.filter((id) => !alreadyScheduled.includes(id));
        if (actionIdsToSchedule.length === 0) return batch;
        batch = scheduleActions(actionIdsToSchedule, batch);
        return batch;
    };

    const handleSaveObjective = (objective) => {
        const batch = saveObjective(objective);
        return batch;
    };

    const value = {
        isSelf,
        isAdmin,
        employeeId: selectedEmployeeId,
        roleId: selectedRoleId,
        currentScheduledActionIds: activeEmployee?.scheduledActionIds || [],
        completedActionIds: activeEmployee?.completedActionIds || [],
        activeEmployee,
        activeAssessment: isSelf ? activeEmployee?.selfAssessment : activeEmployee?.talentAssessment || {},
        employeePath: employeeLinkPrefix,
        liveObjectives,
        cycle,
        snapshot,
        navigateToOrgNode,
        hasChildren,
        onSaveObjective: handleSaveObjective,
        completeActions,
        cancelActions,
        scheduleActions: handleScheduleActions,
        onUnauthorised: handleUnauthorised,
    };

    if (!pageReady || !activeEmployee) return <LoadingIndicator fullscreen message="Role Loading" />;
    return (
        <div className="flex-1 w-full flex items-stretch overflow-hidden">
            <OrgNodeContext.Provider value={value}>
                <div className="flex-1 border-r flex flex-col max-w-80 bg-base-100">
                    <MySideMenu isSelf={isSelf} hasChildren={hasChildren} pages={pages} activePageId={subpage}>
                        <OrgNodeDetailsCard
                            showActions
                            onToggleChangeRole={toggleChangeRole}
                            className="p-3 space-y-3 bg-base-100"
                        />
                    </MySideMenu>
                </div>
                <div className="flex-3 flex flex-col items-stretch overflow-hidden">
                    <Outlet />
                </div>
                <ChangeUserRole
                    key={changeRoleIsOpen ? "force-reset" : "on-toggle"}
                    activeEmployee={activeEmployee}
                    isOpen={changeRoleIsOpen}
                    onToggle={toggleChangeRole}
                />
            </OrgNodeContext.Provider>
        </div>
    );
};

export default OrgNodePage;
