import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateOwnConfig, updateOwnConfigValue } from "../utils/configSlice";

export function useConfig() {
    const dispatch = useDispatch();
    const ownConfig = useSelector((state) => state.config.ownConfig);
    const everyoneConfig = useSelector((state) => state.config.everyoneConfig);

    const updateConfig = useCallback(
        (configUpdate) => {
            const newConfig = { ...ownConfig, ...configUpdate };
            dispatch(updateOwnConfig(newConfig));
        },
        [dispatch, ownConfig]
    );

    const setConfigValue = useCallback(
        (key, value) => {
            dispatch(updateOwnConfigValue({ key, value }));
        },
        [dispatch]
    );

    const updateConfigObject = useCallback(
        (key, value) => {
            const currentValue = ownConfig[key] || {};
            const newValue = { ...currentValue, ...value };
            dispatch(updateOwnConfigValue({ key, newValue }));
        },
        [dispatch, ownConfig]
    );


    return { ownConfig, everyoneConfig, setConfigValue, updateConfig, updateConfigObject };
}
