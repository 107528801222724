import React from "react";

//<EmployeeNotes employeeId={activeEmployee.id} showToolbar hideObjectiveNotes allowCreate />

const NotesPage = () => {
    //const { activeEmployee } = useOrgNodeContext();
    return (
        <div className="page page-padding">
            
        </div>
    );
};

export default NotesPage;
