import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import MyBadge from "./Badge";

const Content = ({ children, isOpen, style }) => {
    if (!isOpen) return null;
    return (
        <ul
            tabIndex={0}
            style={style}
            className="menu dropdown-content border z-20 bg-base-100 p-4 space-y-1 shadow divide-y divide-secondary mt-2"
        >
            {children}
        </ul>
    );
};

const MyDropdown = ({
    title,
    children,
    disabled,
    highlightEnabled = true,
    count,
    toggleIcon: ToggleIcon,
    toggleShape,
    toggleVariant,
    toggleSize,
    toggleWidthClass,
    iconSize,
    width,
    isOpen,
    onToggle,
}) => {
    const dropdownRef = useRef(null);
    const [isOpenState, setIsOpenState] = useState(isOpen);
    const location = useLocation();
    const style = {
        width: width,
    };

    // toggle the isOpen state if it receives it as a prop
    useEffect(() => {
        setIsOpenState(isOpen);
    }, [isOpen]);

    // Set isOpenState to false if the page url changes
    useEffect(() => {
        setIsOpenState(false);
    }, [location.pathname]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpenState(false);
                if (onToggle) {
                    onToggle(false);
                }
            }
        };
        if (isOpenState) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpenState, onToggle]);

    const handleToggle = () => {
        setIsOpenState(!isOpenState);
        if (onToggle) {
            onToggle(!isOpenState);
        }
    };

    const toggleShapeClass = toggleShape && `btn-${toggleShape}`;
    const toggleVariantClass = toggleVariant && `btn-${toggleVariant}`;
    const toggleSizeClass = toggleSize && `btn-${toggleSize}`;

    return (
        <div ref={dropdownRef} className="dropdown dropdown-end">
            <div
                tabIndex={0}
                role="button"
                className={classNames(
                    "btn flex space-x-1 border-none",
                    toggleVariantClass,
                    toggleShapeClass,
                    toggleSizeClass,
                    toggleWidthClass,
                    {
                        "btn-disabled": disabled,
                    }
                )}
                onClick={handleToggle}
            >
                {ToggleIcon && (
                    <ToggleIcon
                        size={iconSize}
                        className={classNames({ "animate-pulse text-primary": !disabled && highlightEnabled })}
                    />
                )}
                {title && <div className="min-w-fit">{title}</div>}
                {count && <MyBadge color="info">{count}</MyBadge>}
            </div>
            <Content isOpen={isOpenState} style={style}>
                {children}
            </Content>
        </div>
    );
};

MyDropdown.propTypes = {
    isOpen: PropTypes.bool,
    toggleColor: PropTypes.string,
    toggleShape: PropTypes.oneOf(["circle", "square"]),
    toggleVariant: PropTypes.string,
    toggleSize: PropTypes.string,
    iconSize: PropTypes.number,
    title: PropTypes.string,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    count: PropTypes.number,
    width: PropTypes.number,
    disableCloseOnClick: PropTypes.bool,
    onToggle: PropTypes.func,
};

MyDropdown.defaultProps = {
    isOpen: false,
    iconSize: 16,
    width: 280,
    toggleSize: "sm",
    onToggle: () => {},
};

export default MyDropdown;
