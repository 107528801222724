import { arrayUnion, increment, serverTimestamp } from "firebase/firestore";
import { useSelector } from "react-redux";
import applogger from "../../../common/utils/applogger";
import { getUniqueId } from "../../../common/utils/getUniqueId";
import { batchSet, createBatch, getDocRef } from "../../../firebase/firebaseActions";
import { removeUndefined } from "../../../helpers/helpers";
import { useEmployeeRefs } from "../../Employees/refs/useEmployeeRefs";
import { useObjectiveActions } from "../../Objectives/hooks/useObjectiveActions";
import { prepObjectiveSnapshot } from "../../Objectives/utils/objectiveSnapshotUtils";

export function useReviewActions(cycleId, iterationId, employeeId, reviewerRef, revieweeRef) {
    const workspaceId = useSelector((state) => state.workspace.workspaceId);
    const userEmployeeId = useSelector((state) => state.user.userEmployeeId);
    const { assessmentsRef, objectivesRef, notesRef } = useEmployeeRefs(employeeId);
    const { saveObjective } = useObjectiveActions(employeeId);

    async function handleSaveDraft(review) {
        const { notes = [], ...reviewData } = review || {};
        const ownNotes = notes.filter((note) => note.authorId === userEmployeeId);
        const reviewToSave = { ...reviewData, notes: ownNotes, unsubmittedSaves: increment(1) };
        try {
            const batch = batchSet(reviewerRef, reviewToSave);
            await batch.commit();
            return true;
        } catch (error) {
            applogger.error("useReviewActions.handleSaveDraft", error);
            return false;
        }
    }

    function handleSaveAssessment(relationship, cycleType, objectives, flags, ratings, batch = createBatch()) {
        const objectiveSnapshot = prepObjectiveSnapshot(objectives || {});
        const assessmentUpdate = {
            employeeId,
            workspaceId,
            relationship,
            cycleType,
            objectives: objectiveSnapshot,
            flags,
            ratings,
            cycleId,
            iterationId,
            reviewerId: userEmployeeId,
            updatedOn: serverTimestamp(),
        };
        removeUndefined(assessmentUpdate);
        const assessmentId = `${cycleId}-${iterationId}-${userEmployeeId}`;
        const assessmentRef = getDocRef(assessmentsRef, assessmentId);
        batch.set(assessmentRef, assessmentUpdate, { merge: true });
        return batch;
    }

    function handleSaveNotes(notes = [], batch = createBatch()) {
        notes.forEach((note) => {
            const noteId = note.id || getUniqueId();
            const noteRef = getDocRef(notesRef, noteId);
            batch.set(noteRef, { ...note, createdOn: serverTimestamp() });
        });
        return batch;
    }

    function handleSaveObjectives(objectives = {}, deleteObjectiveQueue = [], iterationId, batch = createBatch()) {
        Object.values(objectives).forEach((objective) => {
            batch = saveObjective(objective, iterationId, batch);
        });
        deleteObjectiveQueue.forEach((objectiveId) => {
            const objectiveRef = getDocRef(objectivesRef, objectiveId);
            batch.delete(objectiveRef);
        });
        return batch;
    }

    const handleApproveReview = async () => {
        let reviewUpdate = {
            approvedIds: arrayUnion(userEmployeeId),
            completedIds: arrayUnion(userEmployeeId),
        };
        const batch = createBatch();
        batch.set(revieweeRef, reviewUpdate, { merge: true });
        try {
            await batch.commit();
            return true;
        } catch (error) {
            applogger.error("useReviewActions.handleApproveChanges", error);
            return false;
        }
    };

    const handleSubmit = async (relationship, submissionDraft, scheduleActions, iterationId, cycleType) => {
        // Prep the assessment update which will propogate the snapshot
        const { notes, objectives, deleteObjectiveQueue = [], flags, ratings } = submissionDraft;

        // Remove the objectives that are marked for deletion
        Object.values(objectives).forEach((objective) => {
            if (deleteObjectiveQueue.includes(objective.id)) {
                delete objectives[objective.id];
            }
        });

        let batch = handleSaveAssessment(relationship, cycleType, objectives, flags, ratings);
        let submissionUpdate = { isComplete: true, unsubmittedSaves: 0, flags: {}, ratings: {} };

        // Submit any draft objectives
        batch = handleSaveObjectives(objectives, deleteObjectiveQueue, iterationId, batch);
        submissionUpdate.objectives = {};
        submissionUpdate.deleteObjectiveQueue = [];

        // Submit any draft notes
        batch = handleSaveNotes(notes, batch);
        submissionUpdate.notes = [];

        // Schedule any actions from the review
        const { scheduleActionIds } = submissionDraft;
        if (scheduleActionIds?.length > 0) {
            batch = scheduleActions(scheduleActionIds, batch);
        }
        submissionUpdate.scheduleActionIds = [];

        let reviewUpdate = {
            completedIds: arrayUnion(userEmployeeId),
            approvedIds: [userEmployeeId],
            lastSubmittedOn: {
                [userEmployeeId]: serverTimestamp(),
            },
        };
        batch.set(revieweeRef, reviewUpdate, { merge: true });

        // Update submission doc
        batch.set(reviewerRef, submissionUpdate, { merge: true });

        try {
            await batch.commit();
            return true;
        } catch (error) {
            applogger.error("useReviewActions.handleSubmit", error);
            return false;
        }
    };

    return {
        saveDraftReview: handleSaveDraft,
        approveReview: handleApproveReview,
        submitReview: handleSubmit,
    };
}
